import React from "react";

const Footer = () => {
  return (
    <footer class="footer bg-light p-2 mt-4 text-dark">
      <div class="container-fluid" align="center">
        <div class="row">
          <div class="col">
            <p className="col-md-12">
              <strong>
                © 2023-24 AiEnsured - All Rights Reserved <br />
                Testaing Solutions Private Ltd.{" "}
              </strong>
            </p>{" "}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
