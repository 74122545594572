import React,{useEffect} from "react";
import { initializeParse } from './components/Services/parseConfig';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Login from "./components/Autho/Login";
import Main from "./components/Question/Main";
import Forgotpwd from "./components/Autho/Forgotpwd";

function App() {

  useEffect(() => {
    initializeParse();
  }
  , []);
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/domain" element={<Main />} />
            <Route path="/forgotpassword" element={<Forgotpwd />} />

          </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
