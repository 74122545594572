import React, { Suspense, lazy } from "react";
import Navbar from "../Navbar";
import "../../App.css";
const LazyQuestion = lazy(() => import("./Question"));

const Main = () => {
  return (
    <>
      <Navbar />
      <div className="container " style={{ marginTop: "20px" }}>
        <Suspense
          fallback={
            <div className="spinner-container">
              <div
                className="spinner-border custom-spinner"
                role="status"
              ></div>
            </div>
          }
        >
          <LazyQuestion />
        </Suspense>
      </div>
    </>
  );
};

export default Main;

