import React,{useEffect} from "react";
import logo from "../img/logo1.png";
import { Link, useNavigate } from "react-router-dom";
import Parse from "parse";
import { initializeParse } from '../components/Services/parseConfig';
initializeParse();

const Navbar = () => {
  useEffect(() => {
    initializeParse();
  }, []);

  
  const currentUser = Parse.User.current();

  const nevigate = useNavigate();
  const handleLogout = () => {
    Parse.User.logOut().then(() => {
      nevigate("/");
    });
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand" to="/domain">
            <img
              src={logo}
              style={{ width: "200px", height: "40px" }}
              className="mb-3"
              alt="Logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" ></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="https://aiensured.com/contact-us/">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex">
            
            {currentUser !== null && (
              <Link
                className="btn btn-outline-danger "
                to="/"

                id="logout"
                onClick={handleLogout}
              >
                Logout
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
